import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import Dashboard from './components/Dashboard/Dashboard';
import {AuthProvider, HandleRedirect, isLoggedIn} from './components/Auth/AuthProvider';
import { DEFAULT_REDIRECT_URI } from './config/config';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/callback" element={<HandleRedirect />} />
        <Route path="*" element={<Navigate to={DEFAULT_REDIRECT_URI} />} />
      </Routes>
    </Router>
  );
};

const ProtectedRoute = ({ children }) => {
  return isLoggedIn() ? children : <Navigate to={DEFAULT_REDIRECT_URI} />;
};

const RootApp = () => {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
};

export default RootApp;
