import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import LogoutButton from '../Auth/LogoutButton';
import StyledFileInput from "../Files/StyledFileInput";
import {UPLOAD_URL} from "../../config/config";

// Styled Components for responsive layout
const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #f4f7fa;
  @media (max-width: 768px) {
    flex-direction: column;  // Stack content for smaller screens
  }
`;

const Sidebar = styled.div`
  width: 240px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 2px 0 12px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    width: 100%;  // Make sidebar full-width on smaller screens
    box-shadow: none;
    border-bottom: 1px solid #e3e8ed;
  }
`;

const SidebarItem = styled.div`
  margin-bottom: 20px;
  font-size: 1.1rem;
  color: #333;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 6px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e3e8ed;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 10px;
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 40px;
  @media (max-width: 1200px) {
    padding: 30px;  // Reduce padding slightly on smaller wide screens
  }
  @media (max-width: 768px) {
    padding: 20px;  // Further reduce padding for smaller screens
  }
`;

const Heading = styled.h1`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const UploadSection = styled.div`
  border: 2px dashed #ddd;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease-in-out;

  &:hover {
    border-color: #ccc;
  }

  @media (max-width: 1200px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 0.9rem;  // Adjust font size for smaller screens
  }
`;

const MediaList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin-top: 20px;
`;

const MediaItem = styled.li`
  background-color: #fff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  color: #555;

  @media (max-width: 768px) {
    padding: 12px;
    font-size: 0.9rem;
  }
`;
function Dashboard() {
    const [uploadedFiles, setUploadedFiles] = useState([]);

    return (
        <PageContainer>
            {/* Sidebar Navigation */}
            <Sidebar>
                <SidebarItem>Dashboard</SidebarItem>
                <SidebarItem>Upload Media</SidebarItem>
                <SidebarItem>My Files</SidebarItem>
                <LogoutButton />
            </Sidebar>

            {/* Main Content */}
            <ContentContainer>
                <Heading>Welcome to Your Dashboard</Heading>

                <UploadSection>
                    <StyledFileInput
                        uploadUrl={UPLOAD_URL}
                        setUploadedFiles={(newFiles) =>
                            setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles])
                        }
                    />
                </UploadSection>

                {/* Uploaded files list */}
                {uploadedFiles.length > 0 && (
                    <MediaList>
                        {uploadedFiles.map((file, index) => (
                            <MediaItem key={index}>
                                {file.name} ({Math.round(file.size / 1024)} KB)
                            </MediaItem>
                        ))}
                    </MediaList>
                )}
            </ContentContainer>
        </PageContainer>
    );
}

export default Dashboard;

